<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-img src="@/assets/logo.png" max-width="140" class="mr-3"></v-img>
          <h2 class="text-h5 font-weight-medium">Activity Center</h2>
        </div>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="mr-2"
            outlined
          >
            Activity Center
          </v-btn>
          <v-btn color="primary" class="mr-2" @click="$router.push('/groups')">
            Groups
          </v-btn>
          <v-btn color="primary" @click="$router.push('/activities')">
            Activities
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-card class="pa-6" elevation="2">
      <v-row>
        <v-tabs v-model="selectedTab" align-with-title>
          <v-tab
            v-for="(project, index) in projects"
            :key="index"
            class="tab-project"
          >
            {{ project.name }}
          </v-tab>
        </v-tabs>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card class="mb-6 pa-4" outlined>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>User Activity</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchNotifications"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
            <v-skeleton-loader
              v-if="isLoadingNotifications"
              type="list-item, text"
              max-width="100%"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="notificationHeaders"
              :items="filteredNotifications"
              class="elevation-0"
              :items-per-page="itemsPerPage"
              :page.sync="page"
              :footer-props="footerProps"
            >
              <template v-slot:[`item.activities_done`]="{ item }">
                <v-chip
                  v-for="(activity, index) in item.activities_done"
                  :key="index"
                  class="mr-2 mb-1"
                  color="primary"
                  outlined
                  small
                >
                  {{ activity }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card class="pa-4" outlined>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>File Activity</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchFileActivity"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>
            <v-skeleton-loader
              v-if="isLoadingFileActivities"
              type="list-item, text"
              max-width="100%"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="fileActivityHeaders"
              :items="filteredFileActivities"
              class="elevation-0"
              :items-per-page="itemsPerPage"
              :page.sync="filePage"
              :footer-props="footerProps"
            ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      selectedTab: 0,
      searchNotifications: "",
      searchFileActivity: "",
      projects: [
        { name: "AT01_Atlanta" },
        { name: "BC01_Bucharest" },
        { name: "TEST_SYNC" },
      ],
      notificationHeaders: [
        { text: "Confirmed at", value: "confirmed_at" },
        { text: "File Name", value: "file_name" },
        { text: "Group Name", value: "group_name" },
        { text: "User Name", value: "user_full_name" },
        { text: "Activities Done", value: "activities_done" },
      ],
      fileActivityHeaders: [
        { text: "Modified at", value: "last_modified" },
        { text: "File Name", value: "filename" },
        { text: "Path", value: "path" },
      ],
      notifications: [],
      fileActivities: [],
      page: 1,
      filePage: 1,
      itemsPerPage: 5,
      footerProps: {
        "items-per-page-options": [5, 10, 15, { text: "All", value: -1 }],
        showFirstLastPage: true,
        firstIcon: "mdi-page-first",
        lastIcon: "mdi-page-last",
      },
      isLoadingNotifications: true,
      isLoadingFileActivities: true,
    };
  },
  computed: {
    filteredNotifications() {
      return this.notifications.filter((notification) =>
        Object.values(notification).some((val) =>
          String(val)
            .toLowerCase()
            .includes(this.searchNotifications.toLowerCase())
        )
      );
    },
    filteredFileActivities() {
      return this.fileActivities.filter((activity) =>
        Object.values(activity).some((val) =>
          String(val)
            .toLowerCase()
            .includes(this.searchFileActivity.toLowerCase())
        )
      );
    },
  },
  methods: {
    async fetchNotifications() {
      this.isLoadingNotifications = true;
      if (this.projects.length > 0) {
        const projectName = this.projects[this.selectedTab].name;
        try {
          const response = await fetch(
            `https://activity-tracker-backend-production.up.railway.app/api/notifications/?project_name=${projectName}`
          );
          if (response.ok) {
            this.notifications = (await response.json()).reverse();
          } else {
            console.error("Failed to fetch notifications.");
          }
        } catch (error) {
          console.error("Error fetching notifications:", error);
        } finally {
          this.isLoadingNotifications = false;
        }
      }
    },
    async fetchFileActivities() {
      this.isLoadingFileActivities = true;
      if (this.projects.length > 0) {
        const projectName = this.projects[this.selectedTab].name;
        try {
          const response = await fetch(
            `https://activity-tracker-backend-production.up.railway.app/api/file-activities/?project_name=${projectName}`
          );
          if (response.ok) {
            this.fileActivities = (await response.json()).reverse();
          } else {
            console.error("Failed to fetch file activities.");
          }
        } catch (error) {
          console.error("Error fetching file activities:", error);
        } finally {
          this.isLoadingFileActivities = false;
        }
      }
    },
  },
  mounted() {
    this.fetchNotifications();
    this.fetchFileActivities();
  },
  watch: {
    selectedTab() {
      this.fetchNotifications();
      this.fetchFileActivities();
    },
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.v-toolbar-title {
  font-weight: bold;
  color: #000956;
}

.v-tabs {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 4px 0;
}

.v-tabs .v-tab {
  font-weight: 500;
  padding: 8px 16px;
}

.v-tabs .v-tab--active {
  background-color: #000956;
  color: white;
  border-radius: 8px;
}

.tab-project {
  margin-bottom: 0;
}

.v-data-table {
  background-color: #ffffff;
}

.v-data-table-header {
  font-weight: bold;
}

.v-text-field {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
