<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col cols="12" class="d-flex align-center justify-space-between">
        <div class="d-flex align-center">
          <v-img
            src="@/assets/logo.png"
            max-width="140"
            class="mr-3 cursor-pointer"
            @click="navigateToActivityCenter"
          ></v-img>
          <h2 class="text-h5 font-weight-medium">Groups Management</h2>
        </div>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="mr-2"
            @click="$router.push('/activity-center')"
            >Activity Center</v-btn
          >
          <v-btn color="primary" class="mr-2" outlined>Groups</v-btn>
          <v-btn color="primary" @click="$router.push('/activities')"
            >Activities</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-card class="pa-6" elevation="2">
      <v-row>
        <v-col cols="12">
          <v-card class="pa-4" outlined>
            <v-toolbar flat color="transparent">
              <v-toolbar-title>Group Management</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="searchGroups"
                append-icon="mdi-magnify"
                label="Search Groups"
                single-line
                hide-details
                class="mr-4"
              ></v-text-field>
              <v-btn color="primary" @click="openGroupDialog">Add Group</v-btn>
            </v-toolbar>
            <v-skeleton-loader
              v-if="isLoadingGroups"
              type="table"
              :loading="isLoadingGroups"
              class="mx-auto"
            ></v-skeleton-loader>
            <v-data-table
              v-else
              :headers="groupHeaders"
              :items="filteredGroups"
              :items-per-page="itemsPerPage"
              :page.sync="currentPage"
              :footer-props="{
                'items-per-page-options': [5, 10, 15],
                'items-per-page-text': 'Groups per page',
              }"
              class="elevation-0"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click="editGroup(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="confirmDeleteGroup(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="groupDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{
            editingGroup ? "Edit Group" : "Add Group"
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field
              label="Group Name"
              v-model="groupForm.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              label="Members (comma-separated emails)"
              v-model="groupForm.members"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeGroupDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="saveGroup"
            :loading="isLoading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Delete Group</span>
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete the group "{{
            groupToDelete ? groupToDelete.name : ""
          }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="closeDeleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteGroup"
            :loading="isLoading"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="mb-2">
      <v-col cols="12">
        <p class="text-caption grey--text">
          *Group names must be identical to the ones on the SharePoint site
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "GroupsPage",
  data() {
    return {
      resp: null,
      groupDialog: false,
      deleteDialog: false,
      editingGroup: false,
      groupToDelete: null,
      searchGroups: "",
      currentPage: 1,
      itemsPerPage: 5,
      groupForm: {
        name: "",
        members: "",
      },
      groups: [],
      groupHeaders: [
        { text: "Group Name", value: "name" },
        { text: "Members", value: "members.length" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      isLoadingGroups: true,
      isLoading: false,
    };
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((group) =>
        group.name.toLowerCase().includes(this.searchGroups.toLowerCase())
      );
    },
  },
  methods: {
    async fetchGroups() {
      this.isLoadingGroups = true;
      try {
        const response = await fetch(
          "https://activity-tracker-backend-production.up.railway.app/api/group/"
        );
        if (response.ok) {
          this.resp = await response.json();
          // Reverse the order of groups
          this.groups = this.resp.groups
            .map((group) => ({
              ...group,
              members: Array.isArray(group.members) ? group.members : [],
            }))
            .reverse();
        } else {
          console.error("Failed to fetch groups.");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        this.isLoadingGroups = false;
      }
    },
    openGroupDialog() {
      this.groupForm = { name: "", members: "" };
      this.editingGroup = false;
      this.groupDialog = true;
    },
    closeGroupDialog() {
      this.groupDialog = false;
    },
    confirmDeleteGroup(group) {
      this.groupToDelete = group;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteGroup() {
      if (!this.groupToDelete) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `https://activity-tracker-backend-production.up.railway.app/api/group/delete_group/`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: this.groupToDelete.name }),
          }
        );

        if (response.ok) {
          this.fetchGroups();
          this.closeDeleteDialog();
        } else {
          console.error("Failed to delete group.");
        }
      } catch (error) {
        console.error("Error deleting group:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async saveGroup() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const membersArray = this.groupForm.members
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email !== "");

        const payload = {
          name: this.groupForm.name,
          members: membersArray.length > 0 ? membersArray : null,
        };

        try {
          const response = await fetch(
            this.editingGroup
              ? `https://activity-tracker-backend-production.up.railway.app/api/group/update_group/`
              : `https://activity-tracker-backend-production.up.railway.app/api/group/post_group/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": this.csrfToken,
              },
              body: JSON.stringify(payload),
            }
          );

          if (response.ok) {
            this.fetchGroups();
            this.closeGroupDialog();
          } else {
            console.error("Failed to save group.");
          }
        } catch (error) {
          console.error("Error saving group:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    editGroup(group) {
      this.groupForm = {
        name: group.name,
        members: group.members.join(", "),
      };
      this.editingGroup = true;
      this.groupDialog = true;
    },
    navigateToActivityCenter() {
      this.$router.push({ name: "ActivityCenter" });
    },
  },
  mounted() {
    this.fetchGroups();
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.v-dialog__content {
  background-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.v-dialog .v-card {
  background-color: #ffffff !important;
}

.v-dialog {
  background-color: transparent !important;
}

.v-card-title,
.v-card-text,
.v-card-actions {
  background-color: #ffffff;
}

.v-toolbar-title {
  font-weight: bold;
  color: #000956;
}

.v-data-table {
  background-color: #ffffff;
}

.v-data-table-header {
  font-weight: bold;
}

.v-text-field {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
